// CustomCursor.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import defaultCursorImage from "../assets/cursor.png";
import clickableCursorImage from "../assets/cursor.png";
import scrollCursorImage from "../assets/cursor.png";

const Cursor = styled.div`
  width: 40px;
  height: 40px;
  background-size: cover;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
  transition: background-image 0.3s ease;
`;

const CustomCursor = () => {
  const [cursorImage, setCursorImage] = useState(defaultCursorImage);

  useEffect(() => {
    const cursor = document.getElementById("cursor");

    const updateCursorPosition = (e) => {
      cursor.style.left = `${e.clientX}px`;
      cursor.style.top = `${e.clientY}px`;
    };

    const handleMouseEnter = () => {
      setCursorImage(clickableCursorImage);
    };

    const handleMouseLeave = () => {
      setCursorImage(defaultCursorImage);
    };

    const handleScroll = () => {
      setCursorImage(scrollCursorImage);
    };

    document.addEventListener("mousemove", updateCursorPosition);
    document.addEventListener("mouseenter", handleMouseEnter);
    document.addEventListener("mouseleave", handleMouseLeave);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousemove", updateCursorPosition);
      document.removeEventListener("mouseenter", handleMouseEnter);
      document.removeEventListener("mouseleave", handleMouseLeave);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Cursor id="cursor" style={{ backgroundImage: `url(${cursorImage})` }} />
  );
};

export default CustomCursor;
