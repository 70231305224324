import React from "react";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import logo from "../assets/logo.png";
import WalletProvider from "./WalletProvider";

const Navbar = () => {
  const handleLogoClick = () => {
    window.location.reload();
  };

  return (
    <Nav>
      <LeftSection>
        <Logo onClick={handleLogoClick}>
          <img src={logo} alt="logo" />
          <LogoText>
            <h1>
              A<span style={{ color: "#c1ff00" }}>ELON</span>
            </h1>
          </LogoText>
        </Logo>
      </LeftSection>
      <CenterSection>
        <ul>
          <li>
            <ScrollLink to="home" smooth={true} duration={500}>
              Home
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to="buy" smooth={true} duration={500}>
              How to buy
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to="about" smooth={true} duration={500}>
              About
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to="tokenomic" smooth={true} duration={500}>
              Tokenomics
            </ScrollLink>
          </li>
        </ul>
      </CenterSection>
      <WalletProvider />
    </Nav>
  );
};

export default Navbar;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 640px) {
    flex-direction: column;
    position: fixed;
    background-color: #000;
    inset: 0;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    gap: 2rem;
    height: ${(props) => (props.bar ? "100vh" : 0)};
    transition: height 400ms ease-in-out;
    overflow: hidden;
    z-index: 100;
  }

  ul {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-right: 26rem;

    li {
      font-family: "Fredoka One", cursive;
      cursor: pointer;
      color: #9bafc1;

      a {
        color: #9bafc1;
        text-decoration: none;
        font-weight: 400;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 10px;
          bottom: -5px;
          height: 2px;
          background-color: #3498db;
          transform: scale(0);
          transform-origin: right;
          transition: transform 400ms ease-in-out;
        }

        &:hover:before {
          transform: scale(1);
          transform-origin: left;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CenterSection = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  img {
    width: 8rem;
    filter: drop-shadow(0px 10px 10px #c1ff00);
    transition: transform 400ms ease-in-out;
    border-radius: 25px;

    @media (max-width: 640px) {
      width: 4rem;
    }
  }
`;

const LogoText = styled.div`
  h1 {
    font-family: "Fredoka One", cursive;
    font-weight: 600;
    font-size: 1.8rem;
    color: white;

    @media (max-width: 790px) {
      display: none;
    }
  }
`;
