import React from "react";
import styled from "styled-components";
import Card from "./Card";
import Arr from "../../assets/arrow.png";

const Buy = () => {
  return (
    <Container id="buy">
      <h1>
        Farming game coming on Telegram on{" "}
        <span style={{ color: "#c1ff00" }}>November 15th</span>. <br />
        “Farm <span style={{ color: "#c1ff00" }}>AELON </span>for free”
        <a href="https://t.me/aelon_bot" rel="noreferrer" target="_blank">
          <h1>
            <u>
              <img width="68" height="68" src={Arr} alt="arrow--v1" />
              LINK
              <img width="68" height="68" src={Arr} alt="arrow--v1" />
            </u>
          </h1>
        </a>
      </h1>
      <h1>
        How to buy <span style={{ color: "#c1ff00" }}>A</span>ELON
      </h1>
      <Cards>
        <Card
          title={"Create Wallet"}
          disc={`Download Phantom or your wallet of choice from the app store or Google Play for free. Desktop users, download the Google Chrome extension by going to Phantom.`}
          button={"Create Wallet"}
          link={"https://phantom.app/"}
        />
        <Card
          title={"GET SOME SOL"}
          disc={`Have SOL in your wallet to switch to $AELON. If you don’t have any SOL, you can buy directly on Phantom, transfer from another wallet, or buy on another exchange and send it to your wallet.`}
          button={"How to add SOL"}
          link={"https://phantom.app/"}
        />
        <Card
          title={"GO TO Raydium"}
          disc={`Swap, earn, and build on the leading decentralized crypto trading protocol. Raydium will allow you to buy Aelon.`}
          button={"Raydium"}
          link={"https://raydium.io/swap/"}
        />
        <Card
          title={"SWAP FOR AELON "}
          disc={`You can start swapping as soon as you have SOL available! Press ‘Select a token’ and enter the token address.`}
          button={"Swap to AELON"}
          link={"https://raydium.io/swap/"}
        />
      </Cards>
    </Container>
  );
};

export default Buy;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 790px) {
    width: 100%;
  }

  @media (max-width: 660px) {
    width: 100%;
  }

  h1 {
    text-align: center;
    color: white;
    // font-family: "Indie Flower", cursive;
  }

  iframe {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 15px;
  }
`;

const Cards = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr); /* 2-column grid */
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 1rem;
  // font-family: "Indie Flower", cursive;
  // transform: skewY(-3deg);
  padding: 0 2rem;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 790px) {
    width: 100%;
  }

  @media (max-width: 660px) {
    width: 100%;
  }
  button {
    border-radius: 25px 15px;
    background: #c1ff00;
    color: black;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
      background: white;
      color: #000;
    }
  }
`;
