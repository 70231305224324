import React from "react";
import styled from "styled-components";
import R from "../assets/r.png";
const milestones = {
  Q3: [
    "Token fair launch in Raydium",
    "Extensive Twitter marketing campaign",
    "Website design and development",
    "Reach 10k market cap",
  ],
  Q4: [
    "CeX listing and partnerships",
    "Investor pitch with Solana mentors",
    "Buy back from market and burn program",
    "Staking feature",
  ],
  "Q1 25": [
    "Reach 10k holders",
    "AELON Game development",
    "Reach 100 Million Market cap",
  ],
};

const Roadmap = () => {
  return (
    <RoadmapSection>
      <RoadmapTitle><span>R</span>oad<span>M</span>ap</RoadmapTitle>
      <RoadmapTimeline>
        {Object.keys(milestones).map((quarter, index) => (
          <Quarter
            key={index}
            title={quarter}
            milestones={milestones[quarter]}
            isLeft={index % 2 === 0}
          />
        ))}
      </RoadmapTimeline>
    </RoadmapSection>
  );
};

const Quarter = ({ title, milestones, isLeft }) => {
  return (
    <RoadmapQuarter isLeft={isLeft}>
      <QuarterTitle>{title}</QuarterTitle>
      <MilestonesList>
        {milestones.map((milestone, index) => (
          <MilestoneItem key={index}>
            <img width="24" height="24" src={R} alt="rocket" /> {milestone}
          </MilestoneItem>
        ))}
      </MilestonesList>
    </RoadmapQuarter>
  );
};

export default Roadmap;

const RoadmapSection = styled.section`
  padding: 50px 0;
  position: relative;
`;

const RoadmapTitle = styled.h2`
  text-align: center;
  margin-bottom: 50px;
  color: #c1ff00;
`;

const RoadmapTimeline = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    width: 2px;
    height: 100%;
    transform: translateX(-50%);
  }
`;

const RoadmapQuarter = styled.div`
  width: 45%;
  margin-bottom: 50px;
  position: relative;
  padding: 0 20px;

  ${(props) =>
    props.isLeft
      ? "text-align: left; float: left;"
      : "text-align: right; float: right;"}
  clear: both;

  @media (max-width: 640px) {
    width: 100%;
    text-align: center;
    float: none;
    margin-bottom: 30px;
  }
`;

const QuarterTitle = styled.h3`
  font-size: 1.8rem;
  color: white;
  margin-bottom: 20px;
`;

const MilestonesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const MilestoneItem = styled.li`
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: white;
  position: relative;
  padding-left: 30px;
  text-align: left;

  @media (max-width: 640px) {
    text-align: center;
    padding-left: 0;
  }
`;
