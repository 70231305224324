import React from "react";
import styled, { keyframes } from "styled-components";
import bg from "../assets/1.png";
const Header = () => {
  return (
    <StyledHeader>
      <CurvedH1>
        <br />
        {/* A<span>ELON</span> */}
      </CurvedH1>
      
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background-image: url(${bg});
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const moveText = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
`;

const CurvedH1 = styled.h1`
  font-size: 10rem;
  // font-family: "Indie Flower", cursive;
  clip-path: polygon(10 100%, 100% 0, 100% 100%, 0 100%);
  transform: rotate(-30deg);
  animation: ${moveText} 2s infinite;

  img {
    height: 200px;
    width: 200px;
  }

  span {
    color: #c1ff00;
  }

  @media (max-width: 768px) {
    font-size: 6rem;

    img {
      height: 150px;
      width: 150px;
    }
  }

  @media (max-width: 480px) {
    font-size: 4rem;

    img {
      height: 100px;
      width: 100px;
    }
  }
`;

export default Header;
