import React, { useEffect, useState } from "react";
import styled from "styled-components";
const WalletProvider = () => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  const getac = async () => {
    if (window.solana && window.solana.isPhantom) {
      try {
        const response = await window.solana.connect();
        // console.log(
        //   "Connected with Public Key:",
        //   response.publicKey.toString()
        // );
        setWalletConnected(true);
        setWalletAddress(response.publicKey.toString());
      } catch (err) {
        console.error("Connection to Phantom Wallet failed", err);
      }
    } else {
      alert("Phantom Wallet not found! Please install it.");
    }
  };

  const disconnectWallet = () => {
    if (window.solana && window.solana.isPhantom) {
      window.solana.disconnect();
      setWalletConnected(false);
      setWalletAddress("");
      console.log("Disconnected from Phantom Wallet");
    }
  };
  useEffect(() => {
    if (window.solana && window.solana.isPhantom) {
      window.solana.on("connect", () => setWalletConnected(true));
      window.solana.on("disconnect", () => setWalletConnected(false));
    }
  }, []);

  return (
    <ButtonSection>
      {walletConnected ? (
        <>
          <WalletInfo>
            {walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}
          </WalletInfo>
          <button class="button-19" role="button" onClick={disconnectWallet}>
            Disconnect
          </button>
        </>
      ) : (
        <button class="button-19" role="button" onClick={getac}>
          Connect Wallet
        </button>
      )}
    </ButtonSection>
  );
};

export default WalletProvider;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  .button-19 {
    appearance: button;
    background-color: #c1ff00;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: din-round, sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    padding: 13px 16px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: filter 0.2s, border-width 0.2s;
    user-select: none;
    width: 100%;
    white-space: nowrap;
    filter: drop-shadow(0px 10px 10px #c1ff00);
  }

  .button-19:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -4px;
    background-color: #c1ff00;
    border-radius: 16px;
    z-index: -1;
  }

  .button-19:focus,
  .button-19:active {
    user-select: auto;
  }

  .button-19:hover:not(:disabled) {
    filter: brightness(1.1);
  }

  .button-19:disabled {
    cursor: not-allowed;
    background-color: #999999;
  }

  .button-19:active {
    border-width: 4px 0 0;
    background: none;
  }
`;

const WalletInfo = styled.span`
  font-size: 1.2rem;
  color: white;
  margin-right: 1rem;
`;
