import React from "react";
import styled from "styled-components";

const Card = (props) => {
  const { disc, title, button, link, Img } = props;
  return (
    <Container>
      <span>{Img && <img src={Img} alt="Logo" width={100} />}</span>
      <h1>{title}</h1>
      <p>{disc}</p>
      <button onClick={() => window.open(link, "_blank")}>{button}</button>
    </Container>
  );
};

export default Card;

const Container = styled.div`
  border-radius: 50px;
  width: 100%;
  background: #00000;
  padding: 1rem;
  text-align: center;
  color: white;
  span {
    font-size: 4rem;
  }

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 790px) {
    width: 100%;
  }

  @media (max-width: 660px) {
    width: 100%;
  }
  h1 {
    color: #9bafc1;
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }
`;
